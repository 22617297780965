import styled from 'styled-components';

import { media } from 'utils/detection';

export default styled.p`
  display: block;
  color: ${props => props.theme.textBlue};
  font-family: 'Galano Classic';
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  margin: 4px 0;

  ${media.medium`
    display: none;
  `}
`;
