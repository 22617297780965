import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export default styled(motion.div)`
  position: absolute;
  left: 40%;
  top: 15px;
  width: 45vw;
  height: 50vh;
  pointer-events: none;
`;
