import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { hoverSpringSmall } from 'utils/motion';

export default styled(({ className, children }) => {
  return (
    <motion.div
      className={className}
      whileHover={{ x: -10 }}
      transition={hoverSpringSmall}
    >
      {React.Children.toArray(children)}
    </motion.div>
  );
})`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
