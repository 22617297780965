import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';

import Link from 'next/link';
import Wrapper from './styled/Wrapper';
import StyledLink from './styled/Link';
import Line from './styled/Line';
import Label from './styled/Label';

export default class LinkMore extends PureComponent {

  static contextType = ThemeContext;

  render() {
    const {
      className,
      scrollRef,
      link,
      linkAs,
      target,
      label,
      hidden,
      isParallaxed,
      isParallaxActive,
      isLight,
      onClick,
    } = this.props;

    const isLineHidden = hidden || (isParallaxed && !isParallaxActive);
    const useNextLink = typeof link !== 'string' || !link.startsWith('mailto:');

    return (
      <Wrapper className={className} ref={scrollRef}>
        {useNextLink ? (
          <Link
            href={link}
            as={linkAs}
            passHref
          >
            <StyledLink target={target} onClick={onClick}>
              <Line theme={this.context } isLight={isLight} hidden={isParallaxActive} />
              <Label theme={this.context} isLight={isLight}>{label}</Label>
            </StyledLink>
          </Link>
        ) : (
          <StyledLink href={link} target={target} onClick={onClick}>
            <Line theme={this.context } isLight={isLight} hidden={isParallaxActive} />
            <Label theme={this.context} isLight={isLight}>{label}</Label>
          </StyledLink>
        )}
      </Wrapper>
    );
  }
}
