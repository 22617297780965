import PropTypes from 'prop-types';

import GradeModel from './Grade';
import CategoryModel from './Category';
import UploadedImageModel from './UploadedImage';
import CaseStudyModel from './CaseStudy';

export default {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  category: PropTypes.shape(CategoryModel),
  grade: PropTypes.shape(GradeModel),
  image: PropTypes.shape(UploadedImageModel).isRequired,
  casestudy: PropTypes.shape(CaseStudyModel),
}
