import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export default styled(({ className, children, isActive, ...restProps }) => (
  <motion.div
    className={className}
    initial="inactive"
    animate={isActive ? 'active' : 'inactive'}
    {...restProps}
  >
    {React.Children.toArray(children)}
  </motion.div>
))`
  position: relative;
`;
