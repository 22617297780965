import PropTypes from 'prop-types';

import UploadedImageModel from './Category';
import OfferCaseStudyModel from './OfferCaseStudy';

export default {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape(UploadedImageModel),
  caseStudy: PropTypes.shape(OfferCaseStudyModel),
}