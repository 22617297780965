import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.span`
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 15px;
  font-weight: 400;

  ${media.medium`
    font-size: 1.4vw;
    line-height: 2.4vw;
  `}

  ${media.large`
    font-size: 1.1vw;
    line-height: 2vw;
  `}

  ${props =>
    props.isLight &&
    css`
      color: ${props => props.theme.textDark};
    `}
`;
