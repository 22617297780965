import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.div`
  display: none;

  ${media.medium`
    position: absolute;
    display: flex;
    flex-direction: row;
    margin: 1.5vw 0 0 0;
    pointer-events: none;
    transition-property: opacity, visibility;
    transition-duration: 0.5s;
    transition-timing-function: ease;

    ${props =>
      props.hidden &&
      css`
        opacity: 0;
        visibility: hidden;
      `}

    ${props =>
      props.offer &&
      css`
        margin: 0;
        flex-direction: column;
        right: 0;
        align-items: flex-end;
      `}
  `}
`;
