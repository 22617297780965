import styled from 'styled-components';

export default styled.p`
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 1vw;
  font-weight: 500;
  padding: 0;
  margin: 0;
`;
