import PropTypes from 'prop-types';

import UploadedImageModel from './UploadedImage';
import HeadingSectionModel from './HeadingSection';
import MarkdownSectionModel from './MarkdownSection';
import QuoteSectionModel from './QuoteSection';
import GallerySectionModel from './GallerySection';

export default {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape(HeadingSectionModel),
    PropTypes.shape(MarkdownSectionModel),
    PropTypes.shape(QuoteSectionModel),
    PropTypes.shape(GallerySectionModel),
  ])).isRequired,
  images: PropTypes.arrayOf(PropTypes.shape(UploadedImageModel)).isRequired,
}