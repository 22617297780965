import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.a`
  position: relative;
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 30px;
  line-height: 37px;
  font-weight: 400;
  text-decoration: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  ${media.medium`
    font-size: 4.5vw;
    color: #0f416a;
    white-space: nowrap;
    transition-property: color, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease;

    ${props => props.isActive && css`
      color: ${props => props.theme.textLight};
    `}

    ${props => props.isInactive && css`
      color: #0f416a;
      opacity: 0.5;
    `}
  `}
`;
