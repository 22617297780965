import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export default styled(({ className, children, hidden, isActive }) => (
  <motion.div
    className={className}
    variants={{
      hidden: { opacity: 0, scale: 1.5 },
      closed: { opacity: 1, scale:  1.5 },
      open: { opacity: 1, scale: 1 },
    }}
    initial="hidden"
    animate={hidden ? 'hidden' : (isActive ? 'open' : 'closed')}
    transition={{
      type: 'spring',
      damping: 50,
      stiffness: 100,
      mass: 2,
    }}
  >
    {React.Children.toArray(children)}
  </motion.div>
))`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.url});
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;
