import styled from 'styled-components';

import { media } from 'utils/detection';

export default styled.p`
  position: relative;
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  width: 174px;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 23.33px;
  text-decoration: none;
  padding: 0;
  margin: 0 0 6px 0;

  ${media.medium`
    width: 188px;
    margin: 2vw 0 6px 0;
  `}
`;
