import styled from 'styled-components';

export default styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: ${props => Math.ceil(props.widthRatio * 100)}%;
  height: ${props => Math.ceil(props.heightRatio * 100)}%;
  background-color: ${props => (props.offer ? `rgba(0, 0, 0, 0)` : `rgba(0, 14, 26, 0.5)`)};
`;
