import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import ProjectThumbnail from 'components/ProjectThumbnail';

export default styled(({ className, url, offer, isActive }) => (
  <motion.div
    className={className}
    variants={{
      open: { x: 35, scale: 1, opacity: 1 },
      closed: { x: 0, scale: 0.8, opacity: 0 },
    }}
    initial="closed"
    animate={isActive ? 'open' : 'closed'}
    transition={{
      type: 'spring',
      damping: 50,
      stiffness: 100,
      mass: 2,
    }}
  >
    <ProjectThumbnail url={url} offer={offer} hidden={!isActive} />
  </motion.div>
))`
  position: relative;
  width: 100%;
  height: 100%;
`;
