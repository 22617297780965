import styled, { css } from 'styled-components';

import LinkMore from 'components/LinkMore';

export default styled(LinkMore)`
  margin: 0 0 0 110px;
  pointer-events: none;

  ${props => props.offer && css`
    margin: 0;
  `}
`;
