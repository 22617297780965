import styled from 'styled-components';

import { media } from 'utils/detection';

export default styled.p`
    opacity: 0.7;
    color: ${props => props.theme.textLight};
    font-family: 'Galano Classic';
    font-size: 0.7vw;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.44px;
    padding: 0;
    white-space: nowrap;
`;
