import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// import { getImageUrl } from 'utils/imageUrl';

import Wrapper from './styled/Wrapper';
import ImageEl from './styled/ImageEl';
import Overlay from './styled/Overlay';

export default class ProjectThumbnail extends PureComponent {


  static defaultProps = {
    hidden: false,
  };

  state = {
    isLoaded: false,
    imageWidth: 1,
    imageHeight: 1,
    overlayWidthRatio: 1,
    overlayHeightRatio: 1,
  };

  componentDidMount() {
    const { url } = this.props;

    if (url) {
      this.loadImage(url);
    }

    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    const { url, hidden } = this.props;

    if (url !== prevProps.url) {
      this.loadImage(url);
    }

    if (!hidden && hidden !== prevProps.hidden) {
      this.onResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  refWrapper = el => {
    this.wrapperEl = el;
    this.onResize();
  };

  loadImage(url) {
    this.img = new Image();
    this.setState({ isLoaded: false });
    this.img.addEventListener('load', this.onLoadComplete);
    this.img.src = url;
  }

  onLoadComplete = () => {
    this.setState(
      {
        imageWidth: this.img.width,
        imageHeight: this.img.height,
        isLoaded: true,
      },
      () => {
        this.onResize();
      },
    );
  };

  onResize = () => {
    const { isLoaded, imageWidth, imageHeight } = this.state;

    if (!this.wrapperEl || !isLoaded) {
      return;
    }

    const wrapperBounds = this.wrapperEl.getBoundingClientRect();
    const wrapperWidth = wrapperBounds.width;
    const wrapperHeight = wrapperBounds.height;
    const wrapperAspect = wrapperWidth / wrapperHeight;
    const imageAspect = imageWidth / imageHeight;
    let overlayWidthRatio = 1;
    let overlayHeightRatio = 1;

    if (imageAspect > wrapperAspect) {
      // Vertical paddings
      overlayWidthRatio = 1.001;
      overlayHeightRatio = wrapperAspect / imageAspect;
    } else {
      // Horizontal padding
      overlayHeightRatio = 1.001;
      overlayWidthRatio = imageAspect / wrapperAspect;
    }

    this.setState({
      overlayWidthRatio,
      overlayHeightRatio,
    });
  };

  render() {
    const { className, url, hidden, offer } = this.props;
    const { isLoaded, overlayWidthRatio, overlayHeightRatio } = this.state;

    return (
      <Wrapper className={className} ref={this.refWrapper}>
        <ImageEl url={url} hidden={!isLoaded} isActive={!hidden}>
          <Overlay widthRatio={overlayWidthRatio} heightRatio={overlayHeightRatio} offer={offer} />
        </ImageEl>
      </Wrapper>
    );
  }
}
