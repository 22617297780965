import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'utils/detection';

export default styled(({ className, hidden }) => (
  <motion.div
    className={className}
    // animate={{ scaleX: hidden ? 0 : 1, originX: 1 }}
    style={{scaleX: hidden, originX: 1}}
    transition={{
      type: 'spring',
      damping: 20,
      stiffness: 150,
      mass: 1,
    }}
  />
))`
  min-width: 60px;
  width: 80px;
  height: 1px;
  border-bottom: 2px solid ${props => props.theme.textLight};
  margin-right: 16px;

  ${media.medium`
    min-width: 80px;
    width: 118px;
  `}

  ${props => props.isLight && css`
    border-bottom: 2px solid ${props => props.theme.textDark};
  `}
`;
