import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { motionValue } from 'framer-motion';

import { ThemeContext } from 'utils/theme';
import ProjectModel from 'models/Project';
import OfferTypeModel from 'models/OfferType';

// import Link from 'next/link';
import Link from 'next/link';
import Wrapper from './styled/Wrapper';
import Name from './styled/Name';
import Category from './styled/Category';
import TagsWrapper from './styled/TagsWrapper';
import TagWrapper from './styled/TagWrapper';
import TagLabel from './styled/TagLabel';
import TagValue from './styled/TagValue';
import TagSeparator from './styled/TagSeparator';
import LinkMore from './styled/LinkMore';
import ProjectThumbnail from './styled/ProjectThumbnail';
import ProjectThumbnailWrapper from './styled/ProjectThumbnailWrapper';
import MoreContent from './styled/MoreContent';

export default class ProjectListItem extends PureComponent {


  static defaultProps = {
    tagLabelInCollaboration: 'In collaboration with',
    tagLabelCategory: 'Type',
    linkMoreLabel: 'more',
    isActive: false,
    isInactive: false,
    motionStrengthX: 10,
    motionStrengthY: 10,
    offsetX: 0,
    offsetY: 0.3,
    scrollOffsetTop: 0.1,
    scrollOffsetBottom: 0.1,
  };

  static contextType = ThemeContext;

  rafId = null;
  targetMotionOffsetX = -50;
  targetMotionOffsetY = -50;
  motionOffsetX = motionValue('-50%');
  motionOffsetY = motionValue('-40%');
  lastFrameTime = 0;

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.rafId = requestAnimationFrame(this.onFrame);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    cancelAnimationFrame(this.rafId);
  }

  refWrapper = el => {
    if (el) {
      el.addEventListener('mousemove', this.onMouseMove);
    } else {
      this.wrapperEl.removeEventListener('mousemove', this.onMouseMove);
    }

    this.wrapperEl = el;
    this.onResize();
  };

  onMouseMove = throttle(e => {
    const { isActive, motionStrengthX, motionStrengthY, offsetX, offsetY } = this.props;

    if (!isActive) {
      return;
    }

    const rateX = (e.layerX / this.offsetWidth - 0.5 + offsetX) * 2;
    const rateY = (e.layerY / this.offsetHeight - 0.5 + offsetY) * 2;
    const motionOffsetX = rateX * motionStrengthX;
    const motionOffsetY = rateY * motionStrengthY;

    this.targetMotionOffsetX = -motionOffsetX - 50;
    this.targetMotionOffsetY = -motionOffsetY - 50;
  }, 1000 / 20);

  onFrame = time => {
    this.rafId = requestAnimationFrame(this.onFrame);
    const dt = time - this.lastFrameTime;
    this.lastFrameTime = time;

    const currentX = Number.parseFloat(this.motionOffsetX.get());
    const currentY = Number.parseFloat(this.motionOffsetY.get());
    const dx = this.targetMotionOffsetX - currentX;
    const dy = this.targetMotionOffsetY - currentY;
    const speed = Math.min(Math.max((0.05 * dt) / (1000 / 60), 0.01), 1);

    this.motionOffsetX.set(`${currentX + dx * speed}%`);
    this.motionOffsetY.set(`${currentY + dy * speed}%`);
  };

  onResize = () => {
    if (!this.wrapperEl) {
      return;
    }

    this.offsetWidth = this.wrapperEl.offsetWidth;
    this.offsetHeight = this.wrapperEl.offsetHeight;
  };

  render() {
    const {
      className,
      tagLabelInCollaboration,
      tagLabelCategory,
      linkMoreLabel,
      big,
      baseLink,
      isActive,
      isInactive,
      project,
      offer,
      ...restProps
    } = this.props;

    const { name, clients, category, image } = project;
    const link = `${baseLink}/${project.slug}`;

    return (
      <Wrapper className={className} isActive={isActive} {...restProps}>
        <ProjectThumbnailWrapper style={{ x: this.motionOffsetX, y: this.motionOffsetY }}>
          <ProjectThumbnail url={image.url} offer={offer} isActive={isActive} />
        </ProjectThumbnailWrapper>
        <Link
          href={{
            pathname: `${baseLink}/[slug]`,
            query: {
              slug: project.slug,
            },
          }}
          as={link}
          passHref={true}
        >
          <Name
            ref={this.refWrapper}
            big={big}
            theme={this.context}
            isActive={isActive}
            isInactive={isInactive}
          >
            {name}
          </Name>
        </Link>
        {!offer && <Category theme={this.context}>{category && category.name}</Category>}
        <TagsWrapper hidden={!isActive} offer={offer}>
          {!offer && (
            <TagWrapper>
              <TagLabel theme={this.context}>{tagLabelInCollaboration}</TagLabel>
              {clients.map((client, index) => (
                <>
                  {index > 0 && <TagSeparator theme={this.context}>, </TagSeparator>}
                  <TagValue theme={this.context}>{client.name}</TagValue>
                </>
              ))}
            </TagWrapper>
          )}
          {!offer && (
            <TagWrapper>
              <TagLabel theme={this.context}>{tagLabelCategory}</TagLabel>
              <TagValue theme={this.context}>{category && category.name}</TagValue>
            </TagWrapper>
          )}
          {offer && <MoreContent theme={this.context}>{this.props.children}</MoreContent>}
          <LinkMore label={linkMoreLabel} link={link} offer={offer} hidden={!isActive} />
        </TagsWrapper>
      </Wrapper>
    );
  }
}
